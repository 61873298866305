import React, { useState } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import {
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-premium'

import {
    Box,
    Button,
    Tooltip,
} from 'shared-components/material/core'
import { SelectInput, TextInput } from 'shared-components/inputs'
import ExhibitGeniePayModal from 'shared-components/modals/ExhibitGeniePayModal/ExhibitGeniePayModal'
import { Exhibit } from 'generated/graphql'
import AiAnalysisModal from 'shared-components/modals/AiAnalysisModal/AiAnalysisModal'

type FormData = {
    batchUpdate: string,
    copyDescriptionToBates?: boolean,
    exhibitDescription?: string,
    exhibitBates?: string,
    exhibitWitness?: string,
    exhibitNotes?: string,
    exhibitSuppressed?: boolean,
    order?: number,
    exhibitDateString?: string
}

const schema = yup.object({
    batchUpdate: yup.string(), 
    exhibitDescription: yup.string(), 
    exhibitBates: yup.string(),
    exhibitWitness: yup.string(),
    exhibitNotes: yup.string(),
    order: yup.number().required(),
    exhibitDateString: yup.string()
}).required()


function CustomToolbar({
    updateRows,
    getSelectedRows,
    exSetId,
    disputeId,
    setFilterButtonEl,
    batchReorder,
    exSetRefetch,
    userExhibitSetsEnabled,
     } : { 
        updateRows: (formData: FormData) => void,
        getSelectedRows: () => [],
        exSetId: number,
        disputeId: number,
        setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
        batchReorder: (order: number) => void,
        exSetRefetch: () => void,
        userExhibitSetsEnabled: boolean
    }) {
    const [payModal, setPayModal] = useState(false)
    const [aiAnalysisModal, setAiAnalysisModal] = useState(false)
    const selectedRows: Exhibit[] = getSelectedRows()
    const batchUpdateOptions = [
        { label: 'Description', value: 'exhibitDescription'},
        { label: 'Witness', value: 'exhibitWitness'},
        { label: 'Lawyer Notes', value: 'exhibitNotes'},
        { label: 'Bates', value: 'exhibitBates'},
        { label: 'Suppress/Activate', value: 'action'},
        { label: 'Order', value: 'order'},
        { label: 'Date', value: 'exhibitDateString'},
    ]

    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            batchUpdate: '', 
            exhibitDescription: '',
            exhibitBates: '',
            exhibitWitness: '',
            exhibitNotes: '',
            order: 0,
            exhibitDateString: '',
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, watch, formState: { errors }, getValues } = methods
    const isDescription = watch('batchUpdate') === 'exhibitDescription'
    const hasDescription = watch('exhibitDescription')
    const isBates = watch('batchUpdate') === 'exhibitBates'
    const hasBates = watch('exhibitBates')
    const isNotes = watch('batchUpdate') === 'exhibitNotes'
    const hasNotes = watch('exhibitNotes')
    const isWitness = watch('batchUpdate') === 'exhibitWitness'
    const hasWitness = watch('exhibitWitness')
    const isAction = watch('batchUpdate') === 'action'
    const isOrder = watch('batchUpdate') === 'order'
    const hasOrder = watch('order') || watch('order') === 0
    const isDate = watch('batchUpdate') === 'exhibitDateString'
    const hasDate = watch('exhibitDateString')
    
    
    const hasBatchUpdate = getValues('batchUpdate')

    const onSubmit = (formData: FormData) => {
        const { 
            batchUpdate, 
            exhibitDescription, 
            exhibitBates, 
            exhibitNotes, 
            exhibitWitness, 
            order, 
            exhibitDateString 
        } = formData
        let update
        switch (batchUpdate) {
            case 'exhibitDescription':
                update = exhibitDescription
                break;
            case 'exhibitNotes':
                update = exhibitNotes
                break;
            case 'exhibitWitness':
                update = exhibitWitness
                break;
            case 'exhibitBates':
                update = exhibitBates
                break;
            case 'exhibitDateString':
                update = exhibitDateString
                break;
            case 'order':
                update = order
                batchReorder(order as number)
                break;
            default:
                break;
        }
        
        const updateData = {
            batchUpdate,
            update
        }
        if (batchUpdate !== 'order') {
            updateRows(updateData)
        }
    }

    const handleSuppress = (update: boolean) => {
        const updateData = {
            batchUpdate: 'exhibitSuppressed',
            update
        }
        updateRows(updateData)
    }

    const handleCopyDescription = () => {
        const updateData = {
            batchUpdate: 'copyDescriptionToBates',
            update: true
        }
        updateRows(updateData)
    }

    const disableButton = () => {
        if (isDescription && !hasDescription) {
            return true
        }
        if (isBates && !hasBates) {
            return true
        }
        if (isWitness && !hasWitness) {
            return true
        }
        if (isNotes && !hasNotes) {
            return true
        }
        if (isOrder && (!hasOrder || watch('order') === null)) {
            return true
        }
        if (isDate && !hasDate) {
            return true
        }
        if (!hasBatchUpdate) {
            return true
        }
        return false
    }

    const togglePayModal = () => setPayModal(payModal => !payModal)
    
    const toggleAiAnalysisModal = () => setAiAnalysisModal(AiAnalysisModal => !AiAnalysisModal)

    const closeAiAnalysisModal = () => {
        setAiAnalysisModal(false)
        exSetRefetch()
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 5, ml: 2, mr: 2 }}>
            <GridToolbarContainer>
                <GridToolbarFilterButton ref={setFilterButtonEl} className="introFilters" />
                <GridToolbarDensitySelector sx={{ mr: 1}} className="introDensity"/>
                <Tooltip title="Download current list of checked items">
                    <GridToolbarExport 
                        excelOptions={{ allColumns: true }}
                        csvOptions={{ disableToolbarButton: true }}
                        printOptions={{ disableToolbarButton: true }}
                    />
                </Tooltip>
                    {/* <Button
                        {...buttonBaseProps}
                        onClick={() => apiRef.current.exportDataAsExcel()}
                    >
                        Download List
                    </Button> */}
                
                    {selectedRows.length > 0 ? (
                        <FormProvider {...methods}>
                        <SelectInput
                            name="batchUpdate"
                            label="Update Selected Rows"
                            options={batchUpdateOptions}
                            error={errors.batchUpdate !== undefined ? true : false}
                            errorMessage={errors.batchUpdate ? errors.batchUpdate.message : undefined}
                            fullWidth
                            disabled={selectedRows.length === 0}
                            sx={{ 
                                width: '185px', 
                                mr: 1, 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiSelect-outlined': { 
                                    padding: '3.875px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />

                        {isDescription ? (
                            <TextInput
                            name='exhibitDescription'
                            label='Description'
                            error={errors.exhibitDescription !== undefined ? true : false}
                            errorMessage={errors.exhibitDescription ? errors.exhibitDescription.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px', 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', 
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ): null}

                        {isNotes ? (
                            <TextInput
                            name='exhibitNotes'
                            label='Notes'
                            error={errors.exhibitNotes !== undefined ? true : false}
                            errorMessage={errors.exhibitNotes ? errors.exhibitNotes.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px', 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', 
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ): null}

                        {isWitness ? (
                            <TextInput
                            name='exhibitWitness'
                            label='Witness'
                            error={errors.exhibitWitness !== undefined ? true : false}
                            errorMessage={errors.exhibitWitness ? errors.exhibitWitness.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px', 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', 
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ): null}

                        {isBates ? (
                            <TextInput
                            name='exhibitBates'
                            label='Bates'
                            error={errors.exhibitBates !== undefined ? true : false}
                            errorMessage={errors.exhibitBates ? errors.exhibitBates.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px', 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', 
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ): null}

                        {isDate ? (
                            <TextInput
                            name='exhibitDateString'
                            label='Date'
                            error={errors.exhibitDateString !== undefined ? true : false}
                            errorMessage={errors.exhibitDateString ? errors.exhibitDateString.message : undefined}
                            sx={{ 
                                ml: 1, 
                                width: '150px', 
                                top: '0px', 
                                maxHeight: '30.75px !important', 
                                minHeight: '30.75px', 
                                '& .MuiFormLabel-root': { 
                                    fontSize: '0.8125rem', 
                                    padding: '4px 10px', 
                                    lineHeight: '.4rem' 
                                }, 
                                '& .MuiInputBase-input': { 
                                    padding: '4px 10px !important', 
                                    height: '23px' 
                                }
                            }}
                        />
                        ) : null}

                        {isOrder ? (
                            <TextInput
                                name='order'
                                label='Order'
                                error={errors.order !== undefined ? true : false}
                                errorMessage={errors.order ? errors.order.message : undefined}
                                helperText="Insert selected exhibits after the entered order number"
                                type='number'
                                sx={{ 
                                    ml: 1, 
                                    width: '200px', 
                                    top: '0px', 
                                    maxHeight: '30.75px !important', 
                                    minHeight: '30.75px', 
                                    '& .MuiFormLabel-root': { 
                                        fontSize: '0.8125rem', 
                                        padding: '4px 10px', 
                                        lineHeight: '.4rem' 
                                    }, 
                                    '& .MuiInputBase-input': { 
                                        padding: '4px 10px !important', 
                                        height: '23px' 
                                    }
                                }}
                            />
                        ): null}
                        {!isAction && (
                            <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            onClick={handleSubmit(onSubmit)}
                            sx={{ ml: 1 }}
                            disabled={disableButton()}
                            >
                                apply
                            </Button>
                        )}
                        
                        {isAction && (
                            <>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    onClick={() => handleSuppress(false)}
                                    sx={{ ml: 1 }}
                                >
                                    Activate
                                </Button>
                                <Button
                                    variant='contained'
                                    color='error'
                                    size='small'
                                    onClick={() => handleSuppress(true)}
                                    sx={{ ml: 1 }}
                                >
                                    Suppress
                                </Button>
                            </>
                        )}
                        {isBates && (
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                onClick={handleCopyDescription}
                                sx={{ ml: 1 }}
                            >
                                Copy Description
                            </Button>
                        )}
                        {userExhibitSetsEnabled && (
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                onClick={toggleAiAnalysisModal}
                                sx={{ ml: 1 }}
                            >
                                AI Analysis
                            </Button>
                        )}
                    </FormProvider>
                    ) : null}
            </GridToolbarContainer>
            <Button
            variant='contained'
            color='secondary'
            size='small'
            onClick={togglePayModal}
            sx={{ ml: 1 }}
            >
                finish
            </Button>
            {payModal && (
                <ExhibitGeniePayModal 
                    open={payModal} 
                    togglePayModal={togglePayModal} 
                    exSetId={exSetId}
                />
            )}
            {aiAnalysisModal && (
                <AiAnalysisModal
                    open={aiAnalysisModal} 
                    toggleAiAnalysisModal={closeAiAnalysisModal} 
                    exSetId={exSetId}
                    selectedRows={selectedRows}
                    disputeId={disputeId}
                />
            )}
            
        </Box>
    )
  }

  export default CustomToolbar